/* eslint-disable react/no-danger */
import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import "../fonts/fonts-post.css";
import Bio from "../components/Bio";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Panel from "../components/Panel";
import {
  formatAuthorNames,
  formatReadingTime,
  showCreatedOrModifiedAt,
} from "../utils/helpers";
import { rhythm, scale } from "../utils/typography";
import {
  codeToLanguage,
  createLanguageLink,
  loadFontsForCode,
  replaceAnchorLinksByLanguage,
} from "../utils/i18n";

const GITHUB_USERNAME = "cluelesscoders";
const GITHUB_REPO_NAME = "cluelesscoders.com";
const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

const Translations = (props) => {
  const { translations, lang, languageLink, editUrl } = props;

  const readerTranslations = translations.filter((lng) => lng !== "ru");
  const hasRussianTranslation = translations.indexOf("ru") !== -1;

  return (
    <div className="translations">
      <Panel style={{ fontFamily: systemFont }}>
        {translations.length > 0 && (
          <span>
            {hasRussianTranslation && (
              <span>
                Originally written in:{" "}
                {lang === "en" ? (
                  <b>{codeToLanguage("en")}</b>
                ) : (
                  <Link to={languageLink("en")}>English</Link>
                )}
                {" • "}
                {lang === "ru" ? (
                  <b>Русский (авторский перевод)</b>
                ) : (
                  <Link to={languageLink("ru")}>
                    Русский (авторский перевод)
                  </Link>
                )}
                <br />
                <br />
              </span>
            )}
            <span>Translated by readers into: </span>
            {readerTranslations.map((l, i) => (
              <React.Fragment key={l}>
                {l === lang ? (
                  <b>{codeToLanguage(l)}</b>
                ) : (
                  <Link to={languageLink(l)}>{codeToLanguage(l)}</Link>
                )}
                {i === readerTranslations.length - 1 ? "" : " • "}
              </React.Fragment>
            ))}
          </span>
        )}
        {lang !== "en" && (
          <>
            <br />
            <br />
            {lang !== "ru" && (
              <>
                <Link to={languageLink("en")}>Read the original</Link>
                {" • "}
                <a href={editUrl} target="_blank" rel="noopener noreferrer">
                  Improve this translation
                </a>
                {" • "}
              </>
            )}
            <Link to={`/${lang}`}>View all translated posts</Link>{" "}
          </>
        )}
      </Panel>
    </div>
  );
};

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark;
  const siteTitle = get(data, "site.siteMetadata.title");
  let { translations } = pageContext;
  const { previous, next, slug, translatedLinks } = pageContext;
  const lang = post.fields.langKey;

  // Replace original links with translated when available.
  let { html } = post;

  // Replace original anchor links by lang when available in whitelist
  // see utils/whitelist.js
  html = replaceAnchorLinksByLanguage(html, lang);

  translatedLinks.forEach((link) => {
    // jeez
    function escapeRegExp(str) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }
    const translatedLink = `/${lang}${link}`;
    html = html.replace(
      new RegExp(`"${escapeRegExp(link)}"`, "g"),
      `"${translatedLink}"`
    );
  });

  translations = translations.slice();
  translations.sort((a, b) => {
    return codeToLanguage(a) < codeToLanguage(b) ? -1 : 1;
  });

  loadFontsForCode(lang);
  // TODO: this curried function is annoying
  const languageLink = createLanguageLink(slug, lang);
  const enSlug = languageLink("en");
  const editUrl = `https://github.com/${GITHUB_USERNAME}/${GITHUB_REPO_NAME}/edit/master/src/pages/${enSlug.slice(
    1,
    enSlug.length - 1
  )}/index${lang === "en" ? "" : `.${lang}`}.md`;
  const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(
    `https://cluelesscoders.com${enSlug}`
  )}`;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        lang={lang}
        title={post.frontmatter.title}
        description={post.frontmatter.spoiler}
        slug={post.fields.slug}
        pathname={`/blog${post.fields.slug}`}
        authors={post.frontmatter.authors}
        modifiedAt={post.fields.modifiedAt}
        createdAt={post.frontmatter.createdAt}
      />
      <main>
        <article>
          <header>
            <h1 style={{ color: "var(--textTitle)" }}>
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                ...scale(-1 / 5),
                display: "block",
                marginBottom: rhythm(1),
                marginTop: rhythm(-4 / 5),
              }}
            >
              {`${showCreatedOrModifiedAt(
                post.frontmatter.date,
                post.fields.modifiedAt,
                lang
              )}`}
              {` • ${formatReadingTime(post.timeToRead)}`}
              {post.frontmatter.authors &&
                ` • Written by ${formatAuthorNames(post.frontmatter.authors)}`}
            </p>
            {translations.length > 0 && (
              <Translations
                translations={translations}
                editUrl={editUrl}
                languageLink={languageLink}
                lang={lang}
              />
            )}
          </header>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <footer>
            <p>
              <a href={discussUrl} target="_blank" rel="noopener noreferrer">
                Discuss on Twitter
              </a>
              {` • `}
              <a href={editUrl} target="_blank" rel="noopener noreferrer">
                Edit on GitHub
              </a>
            </p>
          </footer>
        </article>
      </main>
      <aside>
        <div
          style={{
            margin: "90px 0 40px 0",
            fontFamily: systemFont,
          }}
        />
        <h3
          style={{
            fontFamily: "Montserrat, sans-serif",
            marginTop: rhythm(0.25),
          }}
        >
          <Link
            style={{
              boxShadow: "none",
              textDecoration: "none",
              color: "var(--pink)",
            }}
            to="/"
          >
            Clueless Coders
          </Link>
        </h3>
        <Bio authors={post.frontmatter.authors} />
        <nav>
          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              listStyle: "none",
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link
                  to={`/blog${previous.fields.slug}`}
                  rel="prev"
                  style={{ marginRight: 20 }}
                >
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/blog${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </aside>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
        createdAt: date
        authors {
          name
          status
          website
          image {
            small
          }
        }
      }
      fields {
        slug
        langKey
        modifiedTime: modifiedTime(formatString: "MMMM DD, YYYY")
        modifiedAt: modifiedTime
      }
    }
  }
`;
